import Vue from 'vue'
import Gallery from '../gallery.vue'

document.addEventListener('turbolinks:load', () => {
  const el = document.getElementById("gallery");

  if(el) {
      const app = new Vue({
        el,
        render: h => h(Gallery, {
          props: JSON.parse(el.dataset.props)
        })
      }).$mount(el)
  }
})

