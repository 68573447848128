<template>
  <div id="gallery">
    <ul style="list-style: none;">
      <li v-for="cat in categories"><a :class="{inactive: !isShowing(cat) }" @click.prevent="showGallery(cat)" href="#" class="button button--secondary">{{cat}}</a></li>
    </ul>
    <gallery v-if="currentPhotos" :images="currentPhotos" :index="index" @close="index = null"></gallery>
    <div class="row">
      <div
        class="image col-md-2"
        v-for="(photo, photoIndex) in currentPhotos"
        :key="photo.id"
        @click="index = photoIndex"
        v-if="currentPhotos"
        :style="backgroundPhoto(photo)"
        ></div>
    </div>
  </div>

</template>

<script>
import VueGallery from 'vue-gallery';
export default {
  props: ['galleries'],
  components: {
    'gallery': VueGallery
  },
  data: function () {
    return {
      currentlyShowing: [],
      index: null
    }
  },
  methods: {
    isShowing(cat) {
      return this.currentlyShowing.includes(cat)
    },
    showGallery(cat) {
      this.currentlyShowing = [cat]
    },
    toggleGallery(cat) {
      if(this.currentlyShowing.includes(cat)) {
        let i = this.currentlyShowing.indexOf(cat)
        this.currentlyShowing.splice(i, 1)
      } else {
        this.currentlyShowing.push(cat)
      }
    },
    backgroundPhoto(photo) {
      return { backgroundImage: `url('${photo.small}')`, width: '100%', height: '200px', backgroundSize: 'cover', backgroundPosition: 'center center' }
    }
  },
  computed: {
    currentGalleries() {
      return this.galleries.filter(g => this.currentlyShowing.includes(g.title))
    },
    currentPhotos() {
      return this.currentGalleries.map((g) => {
        return g.photos
      }).flat()
    },
    categories() {
      return this.galleries.map(c => c.title)
    }
  },
  mounted() {
    this.currentlyShowing.push(this.galleries.map(g => g.title)[0])
  }
}
</script>

<style scoped>
ul {
  padding: 0;
  text-align: center;
}
ul li {
  padding: 0;
  display: inline-block;
}
.button {
  border-radius: 0;
  padding: 8px;
  height: auto;
  min-width: auto;
  margin-right: 5px;
  color: #fff;
  font-size: 12px;
}
.button.inactive {
  color: #003764;
  background: #fff;
  border: 1px solid #003764;
}
.gallery-image {
  position: absolute;
  list-style: none;
  opacity: 0;
  visibility: hidden;
  padding: 10px;
  -webkit-transition: opacity 1s, visibility 1s;
  transition: opacity 1s, visibility 1s;
}
.gallery-image.showing {
  visibility: visible;
  position: relative;
  opacity: 1;
}

    .image {
      float: left;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      border: 1px solid #ebebeb;
      cursor: pointer;
    }
</style>
